<template>
    <div id="play-game-quizz-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">

            <v-col v-if="loading_questions" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'"
                        class="d-flex flex-column justify-center height-300"
                >
                    <div>
                        <v-card-text class="text-center">
                            <v-progress-circular indeterminate :size="100" :width="6" color="primary"/>
                        </v-card-text>
                    </div>
                </v-card>
            </v-col>

            <v-col v-else-if="!loading_questions && actual_question" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title>
                        <v-row>
                            <v-col cols="2"></v-col>
                            <v-col class="d-flex justify-center" cols="8">
                                <v-chip :prepend-icon="actual_question.theme.icon" :color="actual_question.theme.color"
                                        class="ma-2" size="large" label variant="elevated"
                                >
                                    {{ actual_question.theme.label }}
                                </v-chip>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-end align-center">
                                <v-progress-circular v-if="time" :rotate="360" :size="40" :width="7" :model-value="time"
                                                     color="primary"
                                />
                            </v-col>
                        </v-row>
                    </v-card-title>

                    <v-card-text class="text-center text-h5 font-weight-bold line-height-normal">
                        {{ actual_question.label }}
                    </v-card-text>

                    <v-card-text class="mx-4 mb-2">
                        <v-row class="pb-4">
                            <v-btn v-for="proposition in actual_question.propositions" :key="proposition.id"
                                   @click="setPropositionAsAnswer(proposition)"
                                   :class="proposition.is_answer ? 'bg-green-lighten-1 border-0' : ''"
                                   block variant="outlined" class="font-weight-bold mb-4" color="primary"
                            >
                                <span :class="proposition.is_answer ? 'text-white' : ''">{{ proposition.label }}</span>
                            </v-btn>
                        </v-row>

                        <v-row>
                            <v-btn @click="validateAnswer()" :disabled="!proposition_choose"
                                   :loading="loading_validate_proposition" block variant="flat" class="font-weight-bold"
                                   size="large" color="primary"
                            >
                                {{ $t('play_game_quizz_view.validate') }}
                            </v-btn>
                        </v-row>

                        <v-row class="pt-4" justify="end">
                            <dialog-report-component :game_mode_id="game_mode.id" :question_id="actual_question.id"
                                                     type="signal_question"
                            />
                        </v-row>
                    </v-card-text>
                </v-card>
            </v-col>

            <v-col v-if="display_end" class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title class="d-flex justify-center py-4">
                        <p class="font-weight-bold">
                            {{ $t('play_game_quizz_view.thanks_for_participation') }}
                            <v-icon>mdi-emoticon-cool</v-icon>
                        </p>
                    </v-card-title>

                    <v-card-text class="mx-2">
                        <span
                            v-if="$store.getters['game/game'].user_id !== $store.getters['user_connected/user_connected'].id"
                        >
                            Pour avoir vos résultats il faut voir avec la personne qui a créée la partie. Vous pourrez
                            voir directement les résultats dans les futures versions !
                        </span>

                        <span v-else>
                            Vous pouvez retourner sur le suivi de la partie !
                        </span>
                    </v-card-text>

                    <v-card-actions class="mx-4 mb-4">
                        <v-btn
                            v-if="$store.getters['game/game'].user_id !== $store.getters['user_connected/user_connected'].id"
                            @click="this.$router.push({name: 'dashboard'});" block variant="flat" size="large"
                            class="font-weight-bold" color="primary"
                        >
                            {{ $t('play_game_quizz_view.back_to_dashboard') }}
                        </v-btn>

                        <v-btn v-else @click="this.$router.push({name: 'resume_game_quizz'});" block variant="flat"
                               size="large" class="font-weight-bold" color="primary"
                        >
                            {{ $t('play_game_quizz_view.see_participants') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import DialogReportComponent from "../../../components/global/DialogReportComponent";
import AnswerRepository from "../../../services/AnswerRepository";
import GameRepository from "../../../services/GameRepository";
import UserRepository from "../../../services/UserRepository";

export default {
    name: "PlayGameQuizzView",
    components: {DialogReportComponent},
    data() {
        return {
            game_mode: this.$store.getters['game_mode/game_mode'],
            id_game: this.$store.getters['game/game'].id,
            code: this.$store.getters['game/game'].code,
            questions: null,
            loading_questions: true,
            index_question: 0,
            actual_question: null,
            total_questions: null,
            proposition_choose: null,
            loading_validate_proposition: false,
            interval: null,
            time: 0,
            timer: null,

            display_end: false,
        }
    },
    created() {
        this.getGameQuestions(this.id_game).then(() => {
            this.getUserAnswers(this.code);
        });
    },
    methods: {
        async getGameQuestions(idGame) {
            this.loading_questions = true;

            GameRepository.getGameQuestions(idGame).then(response => {
                this.questions = response.data.questions;
                this.actual_question = this.questions[this.index_question];
                this.total_questions = this.questions.length;

                this.timer = (response.data.timer ? response.data.timer * 10 : null);
                if (this.timer) {
                    this.setTimer();
                }
            }).catch(error => {
                this.manageError(error, this.$t('play_game_quizz_view.error_encountered_get_questions'));
                this.$router.push({name: 'dashboard'});
            });
        },

        setTimer() {
            this.time = 0;
            this.interval = setInterval(() => {
                if (this.time === 100) {
                    this.validateAnswer();
                }
                this.time += 0.25;
            }, this.timer / 4);
        },

        getUserAnswers(code) {
            UserRepository.getUserAnswers(code).then(response => {
                if (response.data.length) {
                    this.index_question = response.data.length;
                    this.actual_question = this.questions[this.index_question];
                    if (this.index_question === this.questions.length) {
                        this.display_end = true;
                    }
                }
            }).catch(error => {
                this.manageError(error, this.$t('play_game_quizz_view.error_encountered_get_user_answers'));
                this.$router.push({name: 'dashboard'});
            }).finally(() => {
                this.loading_questions = false;
            });
        },

        setPropositionAsAnswer(proposition) {
            if (this.proposition_choose) {
                this.proposition_choose.is_answer = false;
            }
            this.proposition_choose = proposition;
            proposition.is_answer = true;
        },

        validateAnswer() {
            this.loading_validate_proposition = true;

            AnswerRepository.postAnswerToQuestion(
                this.proposition_choose ? this.proposition_choose.id : null, this.id_game, this.actual_question.id
            ).then(() => {
                clearInterval(this.interval);
                this.proposition_choose = null;
                this.index_question++;
                this.actual_question = this.questions[this.index_question];
                if (this.index_question === this.questions.length) {
                    this.display_end = true;
                } else if (this.timer) {
                    this.setTimer();
                }
            }).catch(error => {
                this.manageError(error, this.$t('play_game_quizz_view.error_encountered_validate_answer'));
            }).finally(() => {
                this.loading_validate_proposition = false;
            });
        },
    },
    beforeUnmount() {
        clearInterval(this.interval);
    },
}
</script>

<style>
.line-height-normal {
    line-height: initial !important;
}
</style>
