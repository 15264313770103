<template>
    <div id="create-game-as-close-as-possible" class="h-100">
        <v-row class="h-100 ma-0 d-flex justify-center" align="center">
            <v-card elevation="4" :class="this.is_mobile ? 'w-100 mx-4' : 'width-500'">
                <v-card-title class="mx-2 mt-4 text-h5 pb-0">
                    {{ $t('create_game_as_close_as_possible.launch_game') }}
                </v-card-title>

                <v-card-actions class="mx-4 mt-4 mb-4">
                    <v-row>
                        <v-col class="pb-0" cols="12">
                            <v-btn @click="this.$router.push({name: 'play_' + this.game_mode.reference})"
                                   size="large" color="primary" block variant="flat" class="font-weight-bold"
                            >
                                {{ $t('create_game_as_close_as_possible.lets_go') }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="this.$router.push({name: 'game_mode'})" block class="mr-2"
                                   color="primary" variant="outlined"
                            >
                                {{ $t('create_game_as_close_as_possible.return') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CreateGameAsCloseAsPossible",
    data() {
        return {
            game_mode: this.$store.getters['game_mode/game_mode']
        }
    }
}
</script>
