<template>
    <div id="my-profile-component" class="w-100">
        <v-form ref="form_update_user">
            <v-card elevation="4" class="d-flex flex-column justify-center py-2">
                <v-card-title>{{ $t('profile_view.personal_information') }}</v-card-title>

                <v-card-text class="pt-4 pb-0">
                    <v-row>
                        <v-col sm="6" cols="12" class="pb-2">
                            <v-text-field v-model="user.last_name" :rules="last_name_rules"
                                          variant="outlined" color="primary"
                                          :label="$t('profile_view.last_name')"
                            />
                        </v-col>
                        <v-col sm="6" cols="12" class="pb-2">
                            <v-text-field v-model="user.first_name" :rules="first_name_rules"
                                          variant="outlined" color="primary"
                                          :label="$t('profile_view.first_name')"
                            />
                        </v-col>

                        <v-col sm="4" cols="12" class="py-2">
                            <v-select v-model="user.gender" :items="genders" :label="$t('profile_view.civility')"
                                      color="primary" variant="outlined" item-title="text" item-value="index"
                            />
                        </v-col>

                        <v-col sm="8" cols="12" class="py-2">
                            <v-text-field v-model="user.phone" :rules="phone_rules" type="number"
                                          variant="outlined" color="primary" :label="$t('profile_view.phone')"
                            />
                        </v-col>

                        <v-col cols="12" class="py-2">
                            <v-text-field v-model="this.$store.getters['user_connected/user_connected'].email"
                                          variant="outlined" color="primary"
                                          class="cursor-text-field-not-allowed"
                                          readonly :label="$t('profile_view.email')"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn @click="updateUser()" :loading="loading_user_profile"
                           class="font-weight-bold mr-2" size="large" color="primary" variant="flat"
                    >
                        {{ $t('profile_view.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import UserRepository from "../../services/UserRepository";

export default {
    name: "MyProfileComponent",
    data() {
        return {
            loading_user_profile: false,
            user: {
                last_name: this.$store.getters['user_connected/user_connected'].last_name,
                first_name: this.$store.getters['user_connected/user_connected'].first_name,
                gender: this.$store.getters['user_connected/user_connected'].gender,
                phone: this.$store.getters['user_connected/user_connected'].phone
            },
            genders: [
                {
                    index: 'woman',
                    text: this.$t('profile_view.woman')
                },
                {
                    index: 'man',
                    text: this.$t('profile_view.man')
                },
                {
                    index: 'unknown',
                    text: this.$t('profile_view.unknown')
                }
            ],

            last_name_rules: [
                v => !!v || this.$t('profile_view.last_name_required'),
                v => v.length < 50 || this.$t('profile_view.last_name_too_long')
            ],
            first_name_rules: [
                v => !!v || this.$t('profile_view.first_name_required'),
                v => v.length < 50 || this.$t('profile_view.first_name_too_long')
            ],
            phone_rules: [
                v => !!v || this.$t('profile_view.phone_required'),
                v => v.length <= 10 || this.$t('profile_view.phone_too_long')
            ],
        }
    },
    methods: {
        async updateUser() {
            const {valid} = await this.$refs.form_update_user.validate();

            if (valid) {
                this.loading_user_profile = true;

                UserRepository.putUpdateMyself(
                    this.user
                ).then(response => {
                    this.$store.dispatch('user_connected/setAuthenticateUserAfterUpdate', response.data);
                    this.showSnackbar('success', this.$t('profile_view.profile_updated'));
                }).catch((error) => {
                    this.manageError(error, this.$t('profile_view.error_encountered_update_profile'));
                }).finally(() => {
                    this.loading_user_profile = false;
                });
            }
        }
    }
}
</script>