<template>
    <div id="profile-view">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center pb-0 px-5" cols="12">
                <v-btn @click="this.$router.push({name: 'dashboard'})" block variant="flat"
                       class="font-weight-bold text-primary" size="large" color="white"
                       append-icon="mdi-arrow-left-circle"
                >
                    {{ $t('profile_view.return') }}
                </v-btn>
            </v-col>

            <v-row class="ma-2">
                <v-col md="6" cols="12">
                    <my-profile-component/>
                </v-col>

                <v-col md="6" cols="12">
                    <my-password-component/>
                </v-col>

                <v-col md="6" cols="12">
                    <avatar-component/>
                </v-col>

                <v-col md="6" cols="12">
                    <my-pseudo-component class="pb-5"/>

                    <send-remark-component/>
                </v-col>
            </v-row>

            <v-col class="d-flex justify-center pt-0 px-5" cols="12">
                <v-btn @click="this.$router.push({name: 'dashboard'})" block variant="flat"
                       class="font-weight-bold text-primary" size="large" color="white"
                       append-icon="mdi-arrow-left-circle"
                >
                    {{ $t('profile_view.return') }}
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import AvatarComponent from "../components/profile-view/AvatarComponent";
import MyPasswordComponent from "../components/profile-view/MyPasswordComponent";
import MyProfileComponent from "../components/profile-view/MyProfileComponent";
import MyPseudoComponent from "../components/profile-view/MyPseudoComponent";
import SendRemarkComponent from "../components/profile-view/SendRemarkComponent";

export default {
    name: "ProfileView",
    components: {AvatarComponent, MyPseudoComponent, MyPasswordComponent, MyProfileComponent, SendRemarkComponent}
}
</script>