<template>
    <div id="create-game-quizz-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title class="mx-2 mt-4 text-h5">
                        {{ $t('create_game_quizz_view.create_game') }}
                    </v-card-title>

                    <v-card-text class="d-flex justify-center mt-4 pb-0 mx-2">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-text-field v-model="code_game" :label="$t('create_game_quizz_view.code_game')"
                                              @keyup.enter="getGameByCode()" color="primary" class="pb-2"
                                              variant="outlined" hide-details
                                />

                                <span class="text-caption text-grey">
                                    {{ $t('create_game_quizz_view.code_generate_automatically') }}
                                </span>
                            </v-col>

                            <v-col cols="12" class="pt-0 pb-4">
                                <v-checkbox v-model="set_timer" color="primary" density="compact" hide-details
                                            :label="$t('create_game_quizz_view.response_time_20')"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-4 mb-4">
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-btn @click="getGameByCode()" :loading="loading_game"
                                       block variant="flat" class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('create_game_quizz_view.create') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-btn @click="this.$router.push({name: 'dashboard'})" block class="mr-2"
                                       color="primary" variant="outlined"
                                >
                                    {{ $t('create_game_quizz_view.return') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GameRepository from "../../../services/GameRepository";

export default {
    name: "CreateGameQuizzView",
    data() {
        return {
            code_game: '',
            set_timer: true,
            loading_game: false,
            total_questions: 20
        }
    },
    methods: {
        getGameByCode() {
            this.loading_game = true;
            if(this.code_game === '') {
                this.code_game = Math.random().toString(16).slice(2);
            }

            GameRepository.postGame(this.code_game, this.set_timer, this.total_questions).then(response => {
                if (response.status === 201) {
                    this.showSnackbar('success', this.$t('create_game_quizz_view.game_created'));
                } else {
                    this.showSnackbar('info', this.$t('create_game_quizz_view.game_existing_joined'));
                }

                this.$store.dispatch('game/setCurrentGame', response.data);
                this.$router.push({name: 'resume_game_quizz'});
            }).catch(error => {
                this.manageError(error, this.$t('create_game_quizz_view.error_encountered_create_game'));
            }).finally(() => {
                this.loading_game = false;
            })
        }
    }
}
</script>