<template>
    <div id="dialog-explications-component">
        <v-btn @click="openDialogExplication()" :disabled="!disabled" block color="primary">
            {{ $t('dialog_explication_component.explications') }}
        </v-btn>

        <v-dialog v-model="dialog_explications" max-width="500px">
            <v-form ref="form_report">
                <v-card>
                    <v-card-title>{{ $t('dialog_explication_component.information_explication') }}</v-card-title>

                    <v-card-text class="py-2 px-4 max-height-400">
                        <v-row class="pb-0">
                            <v-col cols="12">
                                <p class="text-pre-line">{{ explication.description }}</p>
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end pb-4 pt-0">
                        <v-btn @click="dialog_explications = false" class="mr-2" color="primary" variant="outlined">
                            {{ $t('dialog_explication_component.close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "DialogExplicationsComponent",
    props: {
        explication: {
            type: Object,
            require: true
        },
        disabled: {
            type: Boolean,
            require: true
        }
    },
    data() {
        return {
            dialog_explications: false,
        }
    },
    methods: {
        openDialogExplication() {
            this.dialog_explications = true;
        }
    }
}
</script>