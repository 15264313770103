<template>
    <div id="password-reset-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col lg="5" md="6" sm="8" cols="12" class="d-flex align-center justify-center w-100">
                <v-card class="w-100" :loading="loading_get_token_user_invitation">
                    <template v-slot:loader="{ isActive }">
                        <v-progress-linear :active="isActive" color="white" height="4" indeterminate/>
                    </template>

                    <v-card-title class="bg-primary">
                        {{ $t('user_invitation_view.personal_information') }}
                    </v-card-title>

                    <v-card-text class="pb-0">
                        <v-window v-model="step_inscription">
                            <v-window-item :value="1">
                                <v-form ref="form_first_step_inscription">
                                    <v-row>
                                        <v-col cols="12" class="pb-0 pt-8">
                                            <v-text-field v-model="user.pseudo" :rules="pseudo_rules" variant="outlined"
                                                          :label="$t('user_invitation_view.pseudo')" color="primary"
                                            />
                                        </v-col>

                                        <v-col sm="6" cols="12" class="pb-2">
                                            <v-text-field v-model="user.last_name" :rules="last_name_rules"
                                                          :label="$t('user_invitation_view.last_name')"
                                                          variant="outlined" color="primary"
                                            />
                                        </v-col>

                                        <v-col sm="6" cols="12" class="pb-2">
                                            <v-text-field v-model="user.first_name" :rules="first_name_rules"
                                                          variant="outlined" color="primary"
                                                          :label="$t('user_invitation_view.first_name')"
                                            />
                                        </v-col>

                                        <v-col sm="5" cols="12" class="py-2">
                                            <v-select v-model="user.gender" :items="genders" :rules="gender_rules"
                                                      :label="$t('user_invitation_view.civility')" color="primary"
                                                      item-title="text" item-value="index" variant="outlined"
                                            />
                                        </v-col>
                                        <v-col sm="7" cols="12" class="py-2">
                                            <v-text-field v-model="user.phone" :rules="phone_rules" type="number"
                                                          @keyup.enter="nextStep()" variant="outlined" color="primary"
                                                          :label="$t('user_invitation_view.phone')"
                                            />
                                        </v-col>

                                        <v-col cols="12">
                                            <v-text-field v-model="user.email" color="primary" variant="outlined"
                                                          :label="$t('user_invitation_view.email')" readonly
                                                          class="cursor-text-field-not-allowed"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-window-item>

                            <v-window-item :value="2">
                                <v-form ref="form_second_step_inscription">
                                    <v-row>
                                        <v-col cols="12" class="pb-0 pt-8">
                                            <v-text-field v-model="user.password" :rules="password_rules"
                                                          :type="show_password ? 'text' : 'password'"
                                                          :append-inner-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                                                          @click:appendInner="show_password = !show_password"
                                                          variant="outlined" color="primary"
                                                          :label="$t('user_invitation_view.password')"
                                            />
                                        </v-col>
                                        <v-col cols="12">
                                            <v-text-field v-model="user.password_confirm" variant="outlined"
                                                          @keyup.enter="acceptUserInvitation()" color="primary"
                                                          :rules="password_confirm_rules"
                                                          :type="show_password_confirm ? 'text' : 'password'"
                                                          :append-inner-icon="show_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                                          @click:appendInner="show_password_confirm = !show_password_confirm"
                                                          :label="$t('user_invitation_view.confirm_password')"
                                            />
                                        </v-col>
                                    </v-row>
                                </v-form>
                            </v-window-item>
                        </v-window>
                    </v-card-text>

                    <v-card-actions class="mx-2 mb-4">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-btn :loading="loading_accept_user_invitation" @click="nextStep()"
                                       block variant="flat" class="font-weight-bold" size="large" color="primary"
                                >
                                    {{
                                        step_inscription === 1 ? $t('user_invitation_view.next') : $t('user_invitation_view.end_inscription')
                                    }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-btn @click="previousStep()" block class="mr-2"
                                       color="primary" variant="outlined"
                                >
                                    {{ $t('user_invitation_view.return') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import UserInvitationService from "../services/UserInvitationRepository";

export default {
    name: "PasswordResetView",
    data() {
        return {
            step_inscription: 1,

            loading_get_token_user_invitation: true,
            token_user_invitation: null,
            user: {},

            genders: [
                {
                    index: 'WOMAN',
                    text: this.$t('user_invitation_view.woman')
                },
                {
                    index: 'MAN',
                    text: this.$t('user_invitation_view.man')
                },
                {
                    index: 'UNKNOWN',
                    text: this.$t('user_invitation_view.unknown')
                }
            ],

            pseudo_rules: [
                v => !!v || this.$t('user_invitation_view.pseudo_required'),
                v => v.length < 50 || this.$t('user_invitation_view.pseudo_too_long')
            ],
            last_name_rules: [
                v => !!v || this.$t('user_invitation_view.last_name_required'),
                v => v.length < 50 || this.$t('user_invitation_view.last_name_too_long')
            ],
            first_name_rules: [
                v => !!v || this.$t('user_invitation_view.first_name_required'),
                v => v.length < 50 || this.$t('user_invitation_view.first_name_too_long')
            ],
            gender_rules: [
                v => !!v || this.$t('user_view.civility_required')
            ],
            phone_rules: [
                v => !!v || this.$t('user_invitation_view.phone_required'),
                v => v.length <= 10 || this.$t('user_invitation_view.phone_too_long')
            ],
            email_rules: [
                v => !!v || this.$t('user_view.email_required'),
                v => (/^\S+@\S+\.\S+$/).test(v) || this.$t('user_view.wrong_email_format'),
            ],

            show_password: false,
            password_rules: [
                v => !!v || this.$t('user_invitation_view.password_required'),
                v => (/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/).test(v) || this.$t('user_invitation_view.format_password')
            ],

            show_password_confirm: false,
            password_confirm_rules: [
                v => !!v || this.$t('user_invitation_view.confirm_password_required'),
                v => v === this.user.password || this.$t('user_invitation_view.confirm_password_different')
            ],

            loading_accept_user_invitation: false
        }
    },
    methods: {
        getCheckTokenUserInvitation() {
            UserInvitationService.getUserInvitationByToken(this.token_user_invitation).then(response => {
                this.showSnackbar('success', this.$t('user_invitation_view.token_valid'));
                this.user.email = response.data.user.email;
            }).catch(error => {
                let errorMessage = (error.response.status === 422 ?
                        this.$t('user_invitation_view.token_expired') : this.$t('user_invitation_view.wrong_token_invitation')
                );

                this.manageError(error, errorMessage);
                this.$router.push({name: 'login'});
            }).finally(() => {
                this.loading_get_token_user_invitation = false;
            });
        },

        async nextStep() {
            if (this.step_inscription === 1) {
                const {valid} = await this.$refs.form_first_step_inscription.validate();
                if (valid) {
                    this.step_inscription++;
                }
            } else {
                await this.acceptUserInvitation();
            }
        },

        previousStep() {
            if (this.step_inscription === 1) {
                this.$router.push({name: 'login'})
            } else {
                this.step_inscription--;
            }
        },

        async acceptUserInvitation() {
            const {valid} = await this.$refs.form_second_step_inscription.validate();

            if (valid) {
                this.loading_accept_user_invitation = true;

                UserInvitationService.putAcceptUserInvitation(this.token_user_invitation, this.user).then(() => {
                    this.showSnackbar('success', this.$t('user_invitation_view.inscription_finalized'));
                    this.$router.push({name: 'login'});
                }).catch(error => {
                    this.manageError(error, this.$t('user_invitation_view.error_encountered_get_inscription'));
                }).finally(() => {
                    this.loading_accept_user_invitation = false;
                })
            }
        }
    },
    mounted() {
        if (this.$route.query.token !== null && this.$route.query.token !== undefined) {
            this.token_user_invitation = this.$route.query.token;
            this.getCheckTokenUserInvitation();
        } else {
            this.$router.push({name: 'login'});
        }
    }
}
</script>

<style>
.cursor-text-field-not-allowed input {
    cursor: not-allowed;
}
</style>