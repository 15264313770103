<template>
    <div id="join-game-quizz-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-title class="mx-2 mt-4 text-h5">
                        {{ $t('join_game_quizz_view.join_a_game') }}
                    </v-card-title>

                    <v-card-text class="d-flex justify-center mt-4 pb-0 mx-2">
                        <v-text-field v-model="code_game" label="Code de la partie" color="primary" variant="outlined"/>
                    </v-card-text>

                    <v-card-actions class="mx-4 mb-4">
                        <v-row>
                            <v-col class="pb-0" cols="12">
                                <v-btn @click="getGameByCode" :disabled="code_game === ''" :loading="loading_game"
                                       block variant="flat" class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('join_game_quizz_view.join') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12">
                                <v-btn @click="this.$router.push({name: 'dashboard'})" block class="mr-2"
                                       color="primary" variant="outlined"
                                >
                                    {{ $t('join_game_quizz_view.return') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GameRepository from "../../../services/GameRepository";

export default {
    name: "JoinGameQuizzView",
    data() {
        return {
            code_game: '',
            loading_game: false
        }
    },
    methods: {
        getGameByCode() {
            this.loading_game = true;

            GameRepository.postGameInscription(this.code_game).then(response => {
                this.$store.dispatch('game/setCurrentGame', response.data);
                this.showSnackbar('success', this.$t('join_game_quizz_view.game_found'));
                this.$router.push({name: 'play_game_quizz_view'});
            }).catch(error => {
                if (error.response.status === 404) {
                    this.showSnackbar('warning', this.$t('join_game_quizz_view.game_not_found'));
                } else {
                    this.manageError(error, this.$t('join_game_quizz_view.error_encountered_inscription'));
                }
            }).finally(() => {
                this.loading_game = false;
            })
        }
    }
}
</script>