import {IGame} from "@/models/IGame";

const state = () => ({
    game: null,
});

const mutations = {
    SET_CURRENT_GAME(state, game: IGame): void {
        state.game = game;
    }
};

const actions = {
    async setCurrentGame({commit}, game: IGame): Promise<void> {
        await commit('SET_CURRENT_GAME', game);
    },
};

const getters = {
    game: (state): void => state.game,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
