<template>
    <div id="game-mode-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-card-text>
                        <v-row v-for="(game_mode, index) in game_modes" :key="game_mode.id">
                            <v-col cols="9">
                                <p class="text-primary d-flex align-center">
                                    <span @click="navigateToCreatePageGame(game_mode)" class="text-h5 pr-2 pointer">
                                        {{ game_mode.label }}
                                    </span>

                                    <v-tooltip class="text-center" location="bottom">
                                        <template v-slot:activator="{ props }">
                                            <v-icon v-bind="props" @click="openDialogExplications(game_mode)"
                                                    size="24px"
                                            >
                                                mdi-information
                                            </v-icon>
                                        </template>

                                         {{ $t('game_mode_view.instructions_game') }}
                                    </v-tooltip>
                                </p>
                                <p @click="navigateToCreatePageGame(game_mode)" class="pointer">
                                    {{ game_mode.description }}
                                </p>
                            </v-col>
                            <v-col @click="navigateToCreatePageGame(game_mode)" cols="3"
                                   class="d-flex justify-end align-center pointer"
                            >
                                <v-img max-width="100" :src="game_mode.public_path_image"></v-img>
                            </v-col>

                            <v-divider class="mx-2" v-if="index !== game_modes.length - 1"/>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="mx-2 pb-4">
                        <v-btn @click="this.$router.push({name: 'dashboard'})" block class="mr-2"
                               color="primary" variant="outlined"
                        >
                            Retour
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>

        <v-dialog v-model="dialog_show_explications" max-width="500px">
            <v-card>
                <v-card-title>
                    {{ $t('game_mode_view.instructions_game') }}
                    <span class="font-weight-bold">{{ game_mode.label  }}</span>
                </v-card-title>

                <v-card-text class="py-2 px-4 max-height-400">
                    <v-row class="pb-0">
                        <v-col cols="12">
                            <p class="text-pre-line">{{ game_mode.explication }}</p>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end pb-4 pt-0">
                    <v-btn @click="dialog_show_explications = false" class="mr-2" color="primary" variant="outlined">
                        {{ $t('game_mode_view.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    name: "GameModeView",
    data() {
        return {
            game_modes: this.$store.getters['game_mode/game_modes'],
            dialog_show_explications: false,
            game_mode: null,
        }
    },
    mounted() {
        this.$store.dispatch('player/unsetAllPlayers');
    },
    methods: {
        navigateToCreatePageGame(gameMode) {
            this.$store.dispatch('game_mode/setCurrentGameMode', gameMode);
            this.$router.push({name: 'create_' + gameMode.reference});
        },

        openDialogExplications(gameMode) {
            this.game_mode = gameMode;
            this.dialog_show_explications = true;
        }
    }
}
</script>
