import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';

const resource = 'api/answer'

export default {
    postAnswerToQuestion(idProposition: number, idGame: number, idQuestion: number): Promise<AxiosResponse<void>> {
        return axios.post(`${resource}/game/question`, {
            game_id: idGame,
            proposition_id: idProposition,
            question_id: idQuestion
        });
    }
}
