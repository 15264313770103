import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';
import {IUserAuthenticate} from "@/models/IUserAuthenticate";
import {IAnswer} from "@/models/IAnswer";

const API_URL_USER = 'api/user';

export default {
    getUserAnswers(code: string): Promise<AxiosResponse<IAnswer[]>> {
        return axios.get(`${API_URL_USER}/game/${code}/answers`);
    },

    putUpdateMyself(user: IUserAuthenticate): Promise<AxiosResponse<IUserAuthenticate>> {
        return axios.put(API_URL_USER, user);
    },

    putUpdateMyPseudo(pseudo: string): Promise<AxiosResponse<IUserAuthenticate>> {
        return axios.put(`${API_URL_USER}/pseudo`, {pseudo: pseudo});
    },

    putUpdateMyInitialBadgeColor(initialColor: string): Promise<AxiosResponse<void>> {
        return axios.put(`${API_URL_USER}/initial_color`, {initial_color: initialColor});
    },

    putUpdateMyPasswordAuthenticate(oldPassword: string, newPassword: string, newPasswordConfirm: string): Promise<AxiosResponse<void>> {
        return axios.put(`${API_URL_USER}/password`, {
            old_password: oldPassword, new_password: newPassword, new_password_confirm: newPasswordConfirm
        });
    }
}

