<template>
    <div id="create-game-death-minute-view" class="h-100">
        <v-row class="h-100 ma-0 d-flex justify-center" align="center">
            <v-card elevation="4" :class="this.is_mobile ? 'w-100 mx-4' : 'width-500'">
                <v-card-title class="mx-2 mt-4 text-h5 pb-0">
                    {{ $t('create_game_death_minute_view.create_game') }}
                </v-card-title>

                <v-card-text class="d-flex justify-center mt-4 mx-2">
                    <v-row>
                        <v-col cols="12">
                            <v-text-field v-model="first_player_pseudo" color="primary"
                                          :label="$t('create_game_death_minute_view.first_player')"
                                          variant="outlined" hide-details clearable
                            />
                        </v-col>

                        <v-col cols="12">
                            <v-text-field v-model="second_player_pseudo"
                                          :label="$t('create_game_death_minute_view.second_player')"
                                          color="primary" variant="outlined" hide-details clearable
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mx-4 mb-4">
                    <v-row>
                        <v-col class="pb-0" cols="12">
                            <v-btn :disabled="canPlay"
                                   @click="navigateToGameDeathMinute()"
                                   size="large" color="primary" block variant="flat" class="font-weight-bold"
                            >
                                {{ $t('create_game_death_minute_view.lets_go') }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="this.$router.push({name: 'game_mode'})" block class="mr-2"
                                   color="primary" variant="outlined"
                            >
                                {{ $t('create_game_death_minute_view.return') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CreateGameDeathMinuteView",
    data() {
        return {
            first_player_pseudo: '',
            second_player_pseudo: '',
            game_mode: this.$store.getters['game_mode/game_mode']
        }
    },
    computed: {
        canPlay() {
            return !(this.first_player_pseudo !== '' && this.second_player_pseudo !== '' && this.first_player_pseudo &&
                this.second_player_pseudo && this.first_player_pseudo !== this.second_player_pseudo
            )
        }
    },
    mounted() {
        this.$store.dispatch('player/unsetAllPlayers');
    },
    methods: {
        navigateToGameDeathMinute() {
            this.$store.dispatch('player/setPlayer', {
                pseudo: this.getTruncateText(this.first_player_pseudo, 10),
            });
            this.$store.dispatch('player/setPlayer', {
                pseudo: this.getTruncateText(this.second_player_pseudo, 10),
            });

            this.$router.push({ name: 'play_' + this.game_mode.reference });
        }
    }
}
</script>