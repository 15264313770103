import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';
import {IGame} from "@/models/IGame";

const resource = 'api/game'

export default {
    getGameQuestions(idGame: number): Promise<AxiosResponse<IGame>> {
        return axios.get(`${resource}/${idGame}/questions`);
    },

    getGameUsers(idGame: number): Promise<AxiosResponse<IGame>> {
        return axios.get(`${resource}/${idGame}/users`);
    },

    getGameResults(idGame: number): Promise<AxiosResponse<IGame>> {
        return axios.get(`${resource}/${idGame}/result`);
    },

    postGame(code: string, setTimer: boolean, totalQuestions: number): Promise<AxiosResponse<IGame>> {
        return axios.post(`${resource}`, {code: code, set_timer: setTimer, total_questions: totalQuestions});
    },

    postGameInscription(code: string): Promise<AxiosResponse<IGame>> {
        return axios.post(`${resource}/${code}/inscription`);
    },

    putEndGame(idGame: number): Promise<AxiosResponse<void>> {
        return axios.put(`${resource}/${idGame}/end`);
    }
}
