<template>
    <div id="resume-game-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col class="d-flex justify-center">
                <v-card elevation="4" :class="this.is_mobile ? 'w-100' : 'width-500'">
                    <v-alert variant="tonal" color="primary">
                        <v-row>
                            <v-col cols="10">
                                {{ $t('resume_game_quizz_view.code_game') }} <b>{{ game.code }}</b>
                            </v-col>
                            <v-col cols="2" class="d-flex justify-end">
                                <v-icon @click="this.copy(game.code)" class="pointer">
                                    mdi-content-copy
                                </v-icon>
                            </v-col>
                        </v-row>
                    </v-alert>

                    <v-card-title class="text-h5 font-weight-bold d-flex justify-center pt-4">
                        {{ $t('resume_game_quizz_view.player_of_the_game') }}
                    </v-card-title>

                    <v-card-text v-if="loading_users" class="text-center py-6">
                        <v-progress-circular indeterminate :size="64" :width="6" color="primary"/>
                    </v-card-text>

                    <v-card-text v-else class="pb-0">
                        <p class="ml-2 my-2 text-h6" v-if="users.length === 0">
                            {{ $t('resume_game_quizz_view.no_player_yet') }}
                        </p>

                        <v-list v-else>
                            <v-list-item class="pl-0" v-for="user in users" :key="user.id">
                                <v-list-item-title>
                                    <v-icon>mdi-circle-medium</v-icon>
                                    {{ `${user.last_name} ${user.first_name}` }} -
                                    {{ user.answers.length }} / {{ total_questions }}
                                </v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-card-text>

                    <v-card-actions class="mx-4 mb-4">
                        <v-row>
                            <v-col cols="12" class="pb-0">
                                <v-btn @click="getGameByCode()" :loading="loading_game"
                                       block variant="flat" class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('resume_game_quizz_view.participate') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-btn @click="this.$router.push({name: 'result_game_quizz'});"
                                       :disabled="users.length === 0" :loading="loading_end_game" block
                                       variant="flat" class="font-weight-bold" size="large" color="primary"
                                >
                                    {{ $t('resume_game_quizz_view.show_results') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12" class="pb-0">
                                <v-btn block @click="getGameUsers()" variant="outlined" color="primary">
                                    {{ $t('resume_game_quizz_view.actualize') }}
                                </v-btn>
                            </v-col>

                            <v-col cols="12" class="py-2">
                                <v-btn @click="this.$router.push({name: 'create_game_quizz'})" block class="mr-2"
                                       color="primary" variant="outlined"
                                >
                                    {{ $t('resume_game_quizz_view.return') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import GameRepository from "../../../services/GameRepository";

export default {
    name: "ResumeGameQuizzView",
    data() {
        return {
            total_questions: null,
            users: [],
            game: this.$store.getters['game/game'],
            loading_users: false,
            loading_game: false,
            loading_end_game: false,
        }
    },
    created() {
        this.getGameUsers();
    },
    methods: {
        getGameUsers() {
            this.loading_users = true;

            GameRepository.getGameUsers(this.game.id).then(response => {
                this.total_questions = response.data.total_questions;
                this.users = response.data.users;
            }).catch(error => {
                this.manageError(error, this.$t('resume_game_quizz_view.error_encountered_get_users'));
            }).finally(() => {
                this.loading_users = false;
            });
        },

        getGameByCode() {
            this.loading_game = true;

            GameRepository.postGameInscription(this.game.code).then(() => {
                this.showSnackbar('success', this.$t('resume_game_quizz_view.game_found'));
                this.$router.push({name: 'play_game_quizz_view'});
            }).catch(error => {
                if (error.response.status === 404) {
                    this.showSnackbar('warning', this.$t('resume_game_quizz_view.game_not_found'));
                } else {
                    this.manageError(error, this.$t('resume_game_quizz_view.error_encountered_inscription'));
                }
            }).finally(() => {
                this.loading_game = false;
            })
        }
    }
}
</script>
