import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';
import {IGameMode} from "@/models/IGameMode";

const resource = 'api/game_mode'

export default {
    getOnlineGameModes(): Promise<AxiosResponse<IGameMode[]>> {
        return axios.get(`${resource}/online`);
    }
}
