<template>
    <div id="create-game-which-is-intruder" class="h-100">
        <v-row class="h-100 ma-0 d-flex justify-center" align="center">
            <v-card elevation="4" :class="this.is_mobile ? 'w-100 mx-4' : 'width-500'">
                <v-card-title class="mx-2 mt-4 text-h5 pb-0">
                    {{ $t('create_game_which_is_intruder.launch_game') }}
                </v-card-title>

                <v-card-text class="d-flex justify-center mt-4 mx-2">
                    <v-row>
                        <v-col cols="12" class="pb-0">
                            <v-text-field @keyup.enter="list_players.length >= 4 ? '' : addPlayer()" v-model="pseudo"
                                          :label="$t('create_game_which_is_intruder.pseudo')"
                                          color="primary" variant="outlined" hide-details
                            />

                            <v-btn @click="addPlayer()" color="primary" block variant="flat"
                                    class="font-weight-bold mt-4" :disabled="list_players.length >= 4 || pseudo === ''"
                            >
                                {{ $t('create_game_which_is_intruder.add_player') }}
                            </v-btn>
                        </v-col>

                        <v-col class="pb-0 pt-2" cols="12">
                            <v-chip v-for="player in list_players" :color="player.color" class="mr-2 mt-2"
                                    @click:close="removePlayer(player)" :key="player.pseudo" size="large"
                                    variant="elevated" closable flat label
                            >
                                {{ player.pseudo }}
                            </v-chip>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mx-4 mb-4">
                    <v-row>
                        <v-col class="pb-0 pt-4" cols="12">
                            <v-btn @click="this.$router.push({name: 'play_' + this.game_mode.reference})"
                                   :disabled="list_players.length < 2"
                                   size="large" color="primary" block variant="flat" class="font-weight-bold"
                            >
                                {{ $t('create_game_which_is_intruder.lets_go') }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="this.$router.push({name: 'game_mode'})" block class="mr-2"
                                   color="primary" variant="outlined"
                            >
                                {{ $t('create_game_which_is_intruder.return') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-row>
    </div>
</template>

<script>
export default {
    name: "CreateGameWhichIsIntruder",
    data() {
        return {
            pseudo: '',
            list_players: this.$store.getters['player/players'],
            colors: [
                '#FF6F00', '#005500', '#AA0000', '#00AAAA', '#E65100', '#03A9F4', '#00AA00', '#555500', '#90A4AE','#263238',
            ],
            game_mode: this.$store.getters['game_mode/game_mode']
        }
    },
    methods: {
        addPlayer() {
            this.$store.dispatch('player/setPlayer', {
                pseudo: this.getTruncateText(this.pseudo, 10),
                color: this.getRandomColor(),
                total_life: 3
            });
            this.pseudo = '';
        },

        removePlayer(player) {
            this.$store.dispatch('player/unsetPlayer', player);
            this.colors.push(player.color);
        },

        getRandomColor() {
            let random = Math.floor(Math.random() * this.colors.length);
            let color = this.colors[random];
            this.colors.splice(random, 1);

            return color;
        }
    }
}
</script>
