import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css';
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'

export default createVuetify({
    components,
    directives,
    icons: {
        defaultSet: 'mdi',
    },
    display: {
        mobileBreakpoint: 'sm',
    },
    theme: {
        themes: {
            light: {
                dark: false,
                colors: {
                    primary: '#005D72',
                    secondary: '#FCFAF1',
                    accent: '#D3D3D3',
                    error: '#c1201f',
                    info: '#32b3ee',
                    success: '#169316',
                    warning: '#c96d11',
                    black: '#000000',
                    white: '#FFFFFF'
                }
            },
        },
    }
})
