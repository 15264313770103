<template>
    <v-app id="app-page">

        <v-main class="bg-primary background-picture">
            <router-view/>
        </v-main>

        <snackbar-component/>
    </v-app>
</template>

<script>
import './css/app.css'
import SnackbarComponent from "./components/global/SnackbarComponent";

export default {
    name: 'App',
    components: {SnackbarComponent}
}
</script>

<style>
.background-picture {
    background: url(/public/images/background_image.png);
}
</style>