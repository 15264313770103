import axios from './axios';
import router from './router';
import {createAuth} from '@websanova/vue-auth';
import driverAuthBearer from '@websanova/vue-auth/dist/drivers/auth/bearer.esm.js';
import driverHttpAxios from '@websanova/vue-auth/dist/drivers/http/axios.1.x.esm.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.esm.js';

export default createAuth({
    plugins: {
        http: axios,
        router: router
    },
    drivers: {
        http: driverHttpAxios,
        auth: driverAuthBearer,
        router: driverRouterVueRouter,
    },
    options: {
        loginData: {
            url: '/api/auth/login',
            redirect: '',
            fetchData: true
        },
        fetchData: {
            url: '/api/auth/me'
        },
        refreshData: {
            url: '/api/auth/refresh',
        },
        logoutData: {
            url: 'api/auth/logout'
        }
    }
});
