<template>
    <div id="my-password-component" class="w-100">
        <v-form ref="form_update_password">
            <v-card elevation="4" class="d-flex flex-column justify-center py-2">
                <v-card-title>{{ $t('profile_view.manage_password') }}</v-card-title>

                <v-card-text class="pt-4 pb-0">
                    <v-row>
                        <v-col cols="12" class="pb-2">
                            <v-text-field v-model="old_password" :rules="old_password_rules"
                                          :type="show_old_password ? 'text' : 'password'"
                                          :append-inner-icon="show_old_password ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:appendInner="show_old_password = !show_old_password"
                                          variant="outlined" color="primary"
                                          :label="$t('profile_view.old_password')"
                            />
                        </v-col>
                        <v-col cols="12" class="py-2">
                            <v-text-field v-model="new_password" :rules="new_password_rules"
                                          :type="show_new_password ? 'text' : 'password'"
                                          :append-inner-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:appendInner="show_new_password = !show_new_password"
                                          variant="outlined" color="primary"
                                          :label="$t('profile_view.new_password')"
                            />
                        </v-col>
                        <v-col cols="12" class="py-2">
                            <v-text-field v-model="new_password_confirm" :rules="new_password_confirm_rules"
                                          :type="show_new_password_confirm ? 'text' : 'password'"
                                          :append-inner-icon="show_new_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:appendInner="show_new_password_confirm = !show_new_password_confirm"
                                          variant="outlined" color="primary"
                                          :label="$t('profile_view.confirm_new_password')"
                            />
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn @click="updatePassword()" :loading="loading_user_password"
                           class="font-weight-bold mr-2" size="large" color="primary" variant="flat"
                    >
                        {{ $t('profile_view.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import UserRepository from "../../services/UserRepository";

export default {
    name: "MyPasswordComponent",
    data() {
        return {
            loading_user_password: false,

            old_password: '',
            show_old_password: false,
            old_password_rules: [
                v => !!v || this.$t('profile_view.old_password_required')
            ],

            new_password: '',
            show_new_password: false,
            new_password_rules: [
                v => !!v || this.$t('profile_view.new_password_required'),
                v => (/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/).test(v) || this.$t('profile_view.format_new_password')
            ],

            new_password_confirm: '',
            show_new_password_confirm: false,
            new_password_confirm_rules: [
                v => !!v || this.$t('profile_view.confirm_new_password_required'),
                v => v === this.new_password || this.$t('profile_view.confirm_new_password_different')
            ]
        }
    },
    methods: {
        async updatePassword() {
            const {valid} = await this.$refs.form_update_password.validate();

            if (valid) {
                this.loading_user_password = true;

                UserRepository.putUpdateMyPasswordAuthenticate(
                    this.old_password, this.new_password, this.new_password_confirm
                ).then(() => {
                    this.old_password = '';
                    this.new_password = '';
                    this.new_password_confirm = '';
                    this.$refs.form_update_password.reset();
                    this.showSnackbar('success', this.$t('profile_view.password_updated'));
                }).catch((error) => {
                    if (error.response.status === 422) {
                        this.manageError(error, this.$t('profile_view.old_password_incorrect'));
                    } else {
                        this.manageError(error, this.$t('profile_view.error_encountered_update_password'));
                    }
                }).finally(() => {
                    this.loading_user_password = false;
                });
            }
        }
    }
}
</script>
