<template>
    <div id="my-pseudo-component" class="w-100">
        <v-form ref="form_update_pseudo">
            <v-card elevation="4" class="d-flex flex-column justify-center py-2">
                <v-card-title>{{ $t('profile_view.pseudo') }}</v-card-title>

                <v-card-text class="pt-4 pb-0">
                    <v-text-field v-model="pseudo" :rules="pseudo_rules" :label="$t('profile_view.pseudo')"
                                  variant="outlined" color="primary"
                    />
                </v-card-text>

                <v-card-actions class="justify-end">
                    <v-btn @click="updatePseudo()" :loading="loading_update_pseudo"
                           class="font-weight-bold mr-2" size="large" color="primary" variant="flat"
                    >
                        {{ $t('profile_view.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-form>
    </div>
</template>

<script>
import UserRepository from "../../services/UserRepository";

export default {
    name: "MyPseudoComponent",
    data() {
        return {
            loading_update_pseudo: false,
            pseudo: this.$store.getters['user_connected/user_connected'].pseudo,

            pseudo_rules: [
                v => !!v || this.$t('profile_view.pseudo_required'),
                v => v.length < 50 || this.$t('profile_view.pseudo_too_long')
            ]
        }
    },
    methods: {
        async updatePseudo() {
            this.loading_update_pseudo = true;

            const {valid} = await this.$refs.form_update_pseudo.validate();

            if (valid) {
                UserRepository.putUpdateMyPseudo(this.pseudo).then(response => {
                    this.$store.dispatch('user_connected/setPseudoAfterUpdate', response.data.pseudo);
                    this.showSnackbar('success', this.$t('profile_view.pseudo_updated'));
                }).catch((error) => {
                    this.manageError(error, this.$t('profile_view.error_encountered_update_pseudo_profile'));
                }).finally(() => {
                    this.loading_update_pseudo = false;
                });
            }
        }
    }
}
</script>
