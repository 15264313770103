import store from '../plugins/store';
import {createRouter, createWebHashHistory} from 'vue-router';

const arrayAccess = [0, 50, 500];
const routes = [
    {
        path: '/:catchAll(.*)',
        redirect: '/login',
        meta: {auth: false},
    },
    {
        path: '/login',
        name: 'login',
        component: require('../views/LoginView').default,
        meta: {auth: false}
    },
    {
        path: '/404',
        redirect: '/dashboard',
        meta: {auth: true},
    },
    {
        path: '/dashboard',
        name: 'dashboard',
        component: require('../views/DashboardView').default,
        meta: {auth: true}
    },
    {
        path: '/profile-view',
        name: 'profile',
        component: require('../views/ProfileView').default,
        meta: {auth: true}
    },
    {
        path: '/join_game_quizz',
        name: 'join_game_quizz',
        component: require('../views/Game/GameQuizz/JoinGameQuizzView').default,
        meta: {auth: true}
    },
    {
        path: '/game_mode',
        name: 'game_mode',
        component: require('../views/GameModeView').default,
        meta: {auth: true}
    },
    {
        path: '/create_game_as_close_as_possible',
        name: 'create_game_as_close_as_possible',
        component: require('../views/Game/GameAsCloseAsPossible/CreateGameAsCloseAsPossible').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/create_game_minute_of_death',
        name: 'create_game_minute_of_death',
        component: require('../views/Game/GameDeathMinute/CreateGameDeathMinuteView').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/create_game_quizz',
        name: 'create_game_quizz',
        component: require('../views/Game/GameQuizz/CreateGameQuizzView').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/create_game_which_is_intruder',
        name: 'create_game_which_is_intruder',
        component: require('../views/Game/GameWhichIsIntruder/CreateGameWhichIsInstruderView').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/password_reset',
        name: 'password_reset',
        component: require('../views/PasswordResetView.vue').default,
        meta: {auth: false}
    },
    {
        path: '/play_game_as_close_as_possible',
        name: 'play_game_as_close_as_possible',
        component: require('../views/Game/GameAsCloseAsPossible/PlayGameAsCloseAsPossibleView').default,
        meta: {auth: true}
    },
    {
        path: '/play_game_minute_of_death',
        name: 'play_game_minute_of_death',
        component: require('../views/Game/GameDeathMinute/PlayGameMinuteOfDeathView').default,
        meta: {auth: true}
    },
    {
        path: '/play_game_quizz_view',
        name: 'play_game_quizz_view',
        component: require('../views/Game/GameQuizz/PlayGameQuizzView').default,
        meta: {auth: true}
    },

    {
        path: '/play_game_which_is_intruder',
        name: 'play_game_which_is_intruder',
        component: require('../views/Game/GameWhichIsIntruder/PlayGameWhichIsIntruderView').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/resume_game_quizz',
        name: 'resume_game_quizz',
        component: require('../views/Game/GameQuizz/ResumeGameQuizzView').default,
        meta: {auth: true},
        beforeEnter: (to, from, next) => {
            if (arrayAccess.includes(store.getters['user_connected/user_connected'].access_level)) {
                next();
            } else {
                next({name: 'dashboard'});
            }
        },
    },
    {
        path: '/result_game_quizz',
        name: 'result_game_quizz',
        component: require('../views/Game/GameQuizz/ResultGameQuizzView').default,
        meta: {auth: true}
    },
    {
        path: '/user_invitation',
        name: 'user_invitation',
        component: require('../views/UserInvitationView.vue').default,
        meta: {auth: false}
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
});

export default router;
