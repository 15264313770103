import {IUserAuthenticate} from "@/models/IUserAuthenticate";

const state = () => ({
    user_connected: {}
});

const mutations = {
    SET_AUTHENTICATE_USER(state, authenticateUser: IUserAuthenticate): void {
        state.user_connected = authenticateUser;
    },

    SET_AUTHENTICATE_USER_AFTER_UPDATE(state, authenticateUser: IUserAuthenticate): void {
        state.user_connected.first_name = authenticateUser.first_name;
        state.user_connected.last_name = authenticateUser.last_name;
        state.user_connected.gender = authenticateUser.gender;
        state.user_connected.phone = authenticateUser.phone;
    },

    SET_INITIAL_COLOR_AFTER_UPDATE(state, initialColor: string): void {
        state.user_connected.initial_color = initialColor;
    },

    SET_PSEUDO_AFTER_UPDATE(state, pseudo: string): void {
        state.user_connected.pseudo = pseudo;
    }
};

const actions = {
    async setAuthenticateUser({commit}, authenticateUser: IUserAuthenticate): Promise<void> {
        commit('SET_AUTHENTICATE_USER', authenticateUser);
    },

    async setAuthenticateUserAfterUpdate({commit}, user: IUserAuthenticate): Promise<void> {
        commit('SET_AUTHENTICATE_USER_AFTER_UPDATE', user);
    },

    async setInitialColorAfterUpdate({commit}, initialColor: string): Promise<void> {
        commit('SET_INITIAL_COLOR_AFTER_UPDATE', initialColor);
    },

    async setPseudoAfterUpdate({commit}, pseudo: string): Promise<void> {
        commit('SET_PSEUDO_AFTER_UPDATE', pseudo);
    }
};

const getters = {
    user_connected: (state): void => state.user_connected,
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
