<template>
    <div id="password-reset-view" class="h-100">
        <v-row class="h-100 ma-0" justify="center" align="center">
            <v-col lg="4" md="6" sm="8" cols="12" class="d-flex align-center justify-center w-100">

                <v-form ref="form_ask_reset_password" @submit.prevent="askForResetPassword" v-if="!token_reset_password"
                        class="w-100"
                >
                    <v-card class="w-100">
                        <v-card-title class="bg-primary">{{ $t('password_reset_view.reset_password') }}</v-card-title>

                        <v-card-text class="pb-0">
                            <p class="py-4">
                                {{ $t('password_reset_view.reset_password_instruction') }}
                            </p>

                            <v-text-field :label="$t('password_reset_view.email')" v-model="email"
                                          :rules="email_rules"
                            />
                        </v-card-text>

                        <v-card-actions class="mx-2 mb-4">
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <v-btn :loading="loading_send_email_reset_password" @click="askForResetPassword()"
                                           block variant="flat" class="font-weight-bold" size="large" color="primary"
                                    >
                                        {{ $t('password_reset_view.reset') }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-btn @click="this.$router.push({name: 'login'})" block class="mr-2"
                                           color="primary" variant="outlined"
                                    >
                                        {{ $t('password_reset_view.return') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>

                <v-form ref="form_reset_password" class="w-100" v-else>
                    <v-card>
                        <v-card-title class="bg-primary">{{ $t('password_reset_view.reset_password') }}</v-card-title>

                        <v-card-text class="pb-0 pt-6">
                            <v-text-field v-model="new_password" :rules="new_password_rules"
                                          :type="show_new_password ? 'text' : 'password'"
                                          :append-inner-icon="show_new_password ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:appendInner="show_new_password = !show_new_password"
                                          :label="$t('password_reset_view.new_password')" color="primary"
                            />

                            <v-text-field v-model="new_password_confirm" :rules="new_password_confirm_rules"
                                          :type="show_new_password_confirm ? 'text' : 'password'"
                                          :append-inner-icon="show_new_password_confirm ? 'mdi-eye' : 'mdi-eye-off'"
                                          @click:appendInner="show_new_password_confirm = !show_new_password_confirm"
                                          :label="$t('password_reset_view.confirm_new_password')" color="primary"
                            />
                        </v-card-text>

                        <v-card-actions class="mx-2 mb-4">
                            <v-row>
                                <v-col cols="12" class="pb-0">
                                    <v-btn :loading="loading_reset_password" @click="resetPassword()"
                                           block variant="flat" class="font-weight-bold" size="large" color="primary"
                                    >
                                        {{ $t('password_reset_view.reset') }}
                                    </v-btn>
                                </v-col>

                                <v-col cols="12">
                                    <v-btn @click="this.$router.push({name: 'login'})" block class="mr-2"
                                           color="primary" variant="outlined"
                                    >
                                        {{ $t('password_reset_view.return') }}
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-actions>
                    </v-card>
                </v-form>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import PasswordResetService from "../services/PasswordResetRepository";

export default {
    name: "PasswordResetView",
    data() {
        return {
            email: '',
            email_rules: [
                v => !!v || this.$t('password_reset_view.email_required'),
                v => (/^\S+@\S+\.\S+$/).test(v) || this.$t('password_reset_view.wrong_email_format'),
            ],
            loading_send_email_reset_password: false,

            token_reset_password: null,
            new_password: '',
            show_new_password: false,
            new_password_rules: [
                v => !!v || this.$t('password_reset_view.new_password_required'),
                v => (/^\S*(?=\S{8,})(?=\S*[a-z])(?=\S*[A-Z])(?=\S*[\d])\S*$/).test(v) || this.$t('password_reset_view.format_new_password')
            ],

            new_password_confirm: '',
            show_new_password_confirm: false,
            new_password_confirm_rules: [
                v => !!v || this.$t('password_reset_view.confirm_new_password_required'),
                v => v === this.new_password || this.$t('password_reset_view.confirm_new_password_different')
            ],

            loading_reset_password: false,
        }
    },
    methods: {
        async askForResetPassword() {
            const {valid} = await this.$refs.form_ask_reset_password.validate()

            if (valid) {
                this.loading_send_email_reset_password = true;

                PasswordResetService.postAddPasswordReset(this.email).then(() => {
                    this.showSnackbar('success', this.$t('password_reset_view.link_reset_password_send'));
                }).catch(error => {
                    this.manageError(error, this.$t('password_reset_view.error_encountered_email_sent_reset_password'));
                }).finally(() => {
                    this.loading_send_email_reset_password = false;
                    this.$router.push({name: 'login'})
                });
            }
        },

        getResetPassword() {
            PasswordResetService.getPasswordReset(this.token_reset_password).then(response => {
                if(response.data.state === 'USED') {
                    this.showSnackbar('warning', 'Token de réinitialisation déjà utilisé.');
                    this.$router.push({name: 'login'});
                } else if (response.data.state === 'EXPIRED') {
                    this.showSnackbar('warning', 'Token de réinitialisation expiré.');
                    this.$router.push({name: 'login'});
                } else {
                    this.showSnackbar('success', this.$t('password_reset_view.token_reset_password_valid'));
                }
            }).catch(error => {
                this.manageError(error, this.$t('password_reset_view.wrong_token_reset_password'));
            });
        },

        async resetPassword() {
            const {valid} = await this.$refs.form_reset_password.validate()

            if (valid) {
                this.loading_reset_password = true;

                PasswordResetService.putUpdateMyPasswordUnauthenticate(
                    this.token_reset_password, this.new_password, this.new_password_confirm
                ).then(() => {
                    this.loading_reset_password = false;
                    this.showSnackbar('success', this.$t('password_reset_view.password_updated'));
                    this.$router.push({name: 'login'});
                }).catch(error => {
                    this.manageError(error, this.$t('password_reset_view.error_encountered_reset_password'));
                });
            }
        }
    },
    mounted() {
        if (this.$route.query.token !== null && this.$route.query.token !== undefined) {
            this.token_reset_password = this.$route.query.token;
            this.getResetPassword();
        }
    }
}
</script>
