import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';
import {IQuestion} from "@/models/IQuestion";

const resource = 'api/question'

export default {
    getQuestionsWithAnswerByGameModeId(gameModeId: number, limit: number): Promise<AxiosResponse<IQuestion[]>> {
        return axios.get(`${resource}/game_mode/${gameModeId}/with_answer?limit=${limit}`);
    },

    patchIncreaseTotalSeen(idQuestions: Array<number>): Promise<AxiosResponse<void>> {
        return axios.patch(`${resource}/total_seen`, {id_questions: idQuestions});
    },
}
