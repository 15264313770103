import axios from '../plugins/axios';
import {AxiosResponse} from 'axios';
import {IUserInvitation} from "@/models/IUserInvitation";

const API_URL_USER_INVITATION = 'api/user_invitation';

export default {
    getUserInvitationByToken(token: string): Promise<AxiosResponse<IUserInvitation>> {
        return axios.get(`${API_URL_USER_INVITATION}/${token}`);
    },

    putAcceptUserInvitation(token: string, user: any): Promise<AxiosResponse<void>> {
        return axios.put(`${API_URL_USER_INVITATION}/${token}`, user);
    },
}
