import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';
import {IPasswordReset} from "@/models/IPasswordReset";

const API_URL_PASSWORD_RESET = 'api/password_reset';

export default {
    getPasswordReset(token: string): Promise<AxiosResponse<IPasswordReset>> {
        return axios.get(`${API_URL_PASSWORD_RESET}/${token}`);
    },

    postAddPasswordReset(email: string): Promise<AxiosResponse<null>> {
        return axios.post(`${API_URL_PASSWORD_RESET}`, {email: email});
    },

    putUpdateMyPasswordUnauthenticate(token: string, newPassword: string, newPasswordConfirm: string): Promise<AxiosResponse<void>> {
        return axios.put(`${API_URL_PASSWORD_RESET}/${token}/user/unauthenticate`, {
            new_password: newPassword, new_password_confirm: newPasswordConfirm
        });
    }
}
