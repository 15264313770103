<template>
    <div id="play-game-minute-of-death-view" class="h-100">
        <v-row class="h-100 mx-4 d-flex justify-center" justify="center" align="center">
            <template v-if="this.is_mobile">
                <v-card elevation="4" class="d-flex flex-column justify-center width-500">
                    <template v-if="is_ready">
                        <v-card-text>
                            <v-row>
                                <v-col class="d-flex align-center justify-center text-center">
                                    <span :class="current_question.label.length > 90 ? 'text-h6' : 'text-h5'">
                                        {{ current_question.label }}
                                    </span>
                                </v-col>
                            </v-row>

                            <v-row>
                                <v-col class="d-flex justify-center text-center">
                                    <span v-if="is_game_stop" class="text-h6 text-red font-weight-bold">
                                        {{ $t('play_game_death_minute_view.pause') }}
                                    </span>
                                    <span v-else class="text-h6">{{ current_question.propositions[0].label }}</span>
                                </v-col>
                            </v-row>

                            <v-row class="pb-2">
                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="badAnswerOfPlayer()" class="bg-red"
                                           style="height: 75px; width: 90px"
                                    >
                                        <v-icon size="36px">mdi-close-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="goodAnswerOfPlayer()" class="bg-green"
                                           style="height: 75px; width: 90px"
                                    >
                                        <v-icon size="36px">mdi-check-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="is_game_stop ? relaunchGame() : stopGame()" class="bg-primary"
                                           style="height: 75px; width: 90px"
                                    >
                                        <v-icon size="36" v-if="is_game_stop">mdi-play-circle-outline</v-icon>
                                        <v-icon size="36px" v-else>mdi-pause-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-card-text>
                    </template>

                    <template v-else>
                        <template v-if="!is_end">
                            <v-card-title class="justify-center d-flex pt-8 pb-6">
                                {{ text_who_is_next }}
                            </v-card-title>

                            <v-card-text class="justify-center d-flex pb-8">
                                <v-btn @click="launchGame()" text="test" class="bg-primary"
                                       style="height: 100px; width: 120px"
                                >
                                    <v-icon size="80px">mdi-play-outline</v-icon>
                                </v-btn>
                            </v-card-text>
                        </template>

                        <template v-else>
                            <v-card-text class="text-h5 text-center fill-height mt-6">
                                {{ player_winner }} {{ $t('play_game_death_minute_view.player_has_win') }}
                            </v-card-text>

                            <v-card-actions class="d-flex justify-center text-center pb-8">
                                <v-row>
                                    <v-col cols="10" class="offset-1 pb-0">
                                        <v-btn @click="dialog_questions_with_answer = true"
                                               variant="flat" class="mt-4" block
                                               color="primary"
                                        >
                                            {{ $t('play_game_death_minute_view.see_question_asked') }}
                                        </v-btn>
                                    </v-col>

                                    <v-col cols="10" class="offset-1">
                                        <v-btn @click="this.$router.push({name: 'create_game_minute_of_death'})"
                                               color="primary" variant="outlined" block
                                        >
                                            {{ $t('play_game_death_minute_view.return') }}
                                        </v-btn>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </template>
                    </template>

                    <v-divider class="mx-4 border-opacity-100" thickness="2" color="primary"/>

                    <v-card-text class="justify-center d-flex mt-4 mb-2">
                        <v-row>
                            <v-col cols="6" style="border-right: 2px #005D72 solid;">
                                <v-row>
                                    <v-col class="d-flex justify-center py-0" cols="12">
                                        <v-chip class="ma-2" color="primary" size="large" label variant="elevated">
                                            {{ player_one }}
                                        </v-chip>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex justify-center pt-2">
                                        <span class="text-h2">{{ time_player_one }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex justify-center pt-0">
                                        <v-icon @click="openDialogEditPlayerTime(player_one)" class="pointer"
                                                size="x-large"
                                        >
                                            mdi-cog-outline
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="6">
                                <v-row>
                                    <v-col class="d-flex justify-center py-0" cols="12">
                                        <v-chip class="ma-2" color="primary" size="large" label variant="elevated">
                                            {{ player_two }}
                                        </v-chip>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex justify-center pt-2">
                                        <span class="text-h2">{{ time_player_two }}</span>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col class="d-flex justify-center pt-0">
                                        <v-icon @click="openDialogEditPlayerTime(player_two)" class="pointer"
                                                size="x-large"
                                        >
                                            mdi-cog-outline
                                        </v-icon>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
            </template>

            <template v-else>
                <v-card elevation="4" width="100%">
                    <v-row>
                        <v-col cols="4" class="pr-0">
                            <v-container style="border-bottom: 2px #005D72 solid; border-right: 2px #005D72 solid;">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="2"/>
                                        <v-col class="d-flex justify-center" cols="8">
                                            <v-chip class="ma-2" color="primary" size="large" label variant="elevated">
                                                {{ player_one }}
                                            </v-chip>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end align-center">
                                            <v-icon @click="openDialogEditPlayerTime(player_one)" class="pointer"
                                                    size="x-large"
                                            >
                                                mdi-cog-outline
                                            </v-icon>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="d-flex justify-center">
                                            <span class="text-h1">{{ time_player_one }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-container>

                            <v-container style="border-right: 2px #005D72 solid">
                                <v-col cols="12">
                                    <v-row>
                                        <v-col cols="2"/>
                                        <v-col class="d-flex justify-center" cols="8">
                                            <v-chip class="ma-2" color="primary" size="large" label variant="elevated">
                                                {{ player_two }}
                                            </v-chip>
                                        </v-col>
                                        <v-col cols="2" class="d-flex justify-end align-center">
                                            <v-icon @click="openDialogEditPlayerTime(player_two)" class="pointer"
                                                    size="x-large"
                                            >
                                                mdi-cog-outline
                                            </v-icon>
                                        </v-col>
                                    </v-row>

                                    <v-row>
                                        <v-col class="d-flex justify-center">
                                            <span class="text-h1">{{ time_player_two }}</span>
                                        </v-col>
                                    </v-row>
                                </v-col>
                            </v-container>
                        </v-col>

                        <v-col v-if="is_ready" cols="8">
                            <v-row>
                                <v-col class="d-flex align-center justify-center text-center height-250">
                                    <span class="pa-2"
                                          :class="current_question.label.length > 90 ? 'text-h4' : 'text-h3'"
                                    >
                                        {{ current_question.label }}
                                    </span>
                                </v-col>
                            </v-row>

                            <v-row class="pb-4">
                                <v-col class="d-flex justify-center text-center">
                                    <span v-if="is_game_stop" class="text-h5 text-red font-weight-bold">
                                        {{ $t('play_game_death_minute_view.pause') }}
                                    </span>
                                    <span v-else class="text-h5">{{ current_question.propositions[0].label }}</span>
                                </v-col>
                            </v-row>

                            <v-row class="pb-8">
                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="badAnswerOfPlayer()" class="bg-red" style="height: 100px; width: 200px">
                                        <v-icon size="65px">mdi-close-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>

                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="goodAnswerOfPlayer()" class="bg-green"
                                           style="height: 100px; width: 200px">
                                        <v-icon size="65px">mdi-check-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>


                                <v-col cols="4" class="d-flex justify-center align-center">
                                    <v-btn @click="is_game_stop ? relaunchGame() : stopGame()" class="bg-primary"
                                           style="height: 100px; width: 200px"
                                    >
                                        <v-icon size="65px" v-if="is_game_stop">mdi-play-circle-outline</v-icon>
                                        <v-icon size="65px" v-else>mdi-pause-circle-outline</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-col>

                        <v-col v-else cols="8">
                            <v-row v-if="!is_end" class="h-100">
                                <v-col cols="12" class="d-flex align-center">
                                    <v-card class="mr-3" style="width: 100%" elevation="0">
                                        <v-card-title class="justify-center d-flex" style="height: 48px">
                                            {{ text_who_is_next }}
                                        </v-card-title>

                                        <v-card-text class="justify-center d-flex">
                                            <v-btn @click="launchGame()" text="test" class="bg-primary"
                                                   style="height: 150px; width: 250px"
                                            >
                                                <v-icon size="100px">mdi-play-outline</v-icon>
                                            </v-btn>
                                        </v-card-text>
                                    </v-card>
                                </v-col>
                            </v-row>

                            <v-row v-else class="h-100">
                                <v-col cols="12" class="d-flex align-center">
                                    <v-card class="mr-3" style="width: 100%" elevation="0">
                                        <v-card-text class="text-h2 text-center fill-height">
                                            {{ player_winner }} {{ $t('play_game_death_minute_view.player_has_win') }}
                                        </v-card-text>

                                        <v-card-actions class="d-flex justify-center text-center">
                                            <v-row>
                                                <v-col cols="8" class="offset-2 pb-0">
                                                    <v-btn @click="dialog_questions_with_answer = true"
                                                           variant="flat" class="mt-4" block
                                                           color="primary"
                                                    >
                                                        {{ $t('play_game_death_minute_view.see_question_asked') }}
                                                    </v-btn>
                                                </v-col>

                                                <v-col cols="8" class="offset-2">
                                                    <v-btn @click="this.$router.push({name: 'create_game_minute_of_death'})"
                                                           color="primary" variant="outlined" block
                                                    >
                                                        {{ $t('play_game_death_minute_view.return') }}
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-card-actions>
                                    </v-card>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </template>
        </v-row>

        <v-dialog v-model="dialog_which_player_begin" persistent max-width="400px">
            <v-card>
                <v-card-title>
                    {{ $t('play_game_death_minute_view.which_player_began') }}
                </v-card-title>

                <v-card-text class="py-8">
                    <v-row>
                        <v-col class="d-flex justify-center align-center text-center">
                            <v-card @click="player_which_play = player_one"
                                    :class="player_which_play === player_one ? 'bg-green' : ''"
                                    elevation="0" style="border: 2px solid black" class="pa-8 pointer rounded-xl"
                            >
                                <p>{{ player_one }}</p>
                            </v-card>
                        </v-col>

                        <v-col class="d-flex justify-center align-center text-center">
                            <v-card @click="player_which_play = player_two"
                                    :class="player_which_play === player_two ? 'bg-green' : ''"
                                    elevation="0" style="border: 2px solid black" class="pa-8 pointer rounded-xl"
                            >
                                <p>{{ player_two }}</p>
                            </v-card>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mx-4 mb-2">
                    <v-row>
                        <v-col class="pb-0" cols="12">
                            <v-btn @click="getGameDeathMinuteQuestions()" :disabled="player_which_play === ''"
                                   :loading="loading_questions"
                                   block variant="flat" class="font-weight-bold" size="large" color="primary"
                            >
                                {{ $t('play_game_death_minute_view.lets_go') }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="this.$router.push({name: 'create_game_minute_of_death'})" block class="mr-2"
                                   color="primary" variant="outlined"
                            >
                                {{ $t('play_game_death_minute_view.return') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_edit_time_player" persistent max-width="400px">
            <v-card>
                <v-card-title class="d-flex justify-center">
                    {{ $t('play_game_death_minute_view.modify_time_of') }} {{ player_time_to_edit }}
                </v-card-title>

                <v-card-text>
                    <v-row>
                        <v-col cols="12" class="d-flex align-center justify-center">
                            <v-icon @click="player_actual_time_to_edit--" size="72" class="h-100"
                                    style="border: 1px solid black"
                            >
                                mdi-minus
                            </v-icon>
                            <span class="text-h2 pa-4" style="border-top: 1px solid black; border-bottom: 1px solid black">
                                {{ player_actual_time_to_edit }}
                            </span>
                            <v-icon @click="player_actual_time_to_edit++" size="72" class="h-100 pointer"
                                    style="border: 1px solid black"
                            >
                                mdi-plus
                            </v-icon>
                        </v-col>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mx-2 mb-2">
                    <v-row>
                        <v-col class="pb-0" cols="12">
                            <v-btn @click="editTimePlayer()" class="font-weight-bold mr-2" color="primary" block
                                    size="large" variant="flat"
                            >
                                {{ $t('play_game_death_minute_view.modify_time') }}
                            </v-btn>
                        </v-col>

                        <v-col cols="12">
                            <v-btn @click="dialog_edit_time_player = false" class="mr-2" color="primary" block
                                   variant="outlined"
                            >
                                {{ $t('play_game_death_minute_view.close') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>

        <v-dialog v-model="dialog_questions_with_answer" persistent max-width="500px">
            <v-card>
                <v-card-title class="text-h4 d-flex justify-center">
                    {{ $t('play_game_death_minute_view.questions_read') }}
                </v-card-title>

                <v-card-text style="max-height: 500px; overflow-y: auto;">
                    <v-row class="d-flex text-center justify-center" v-for="(question_read, index) in questions_read"
                           :key="question_read.id"
                    >
                        <v-col class="pa-0" cols="12">
                            <p>{{ question_read.label }}</p>
                        </v-col>

                        <v-col cols="12" class="pt-2 pb-3">
                            <v-chip :class="question_read.user_has_good_answer ? 'bg-green-lighten-1' : 'bg-red-lighten-1'">
                                {{ question_read.propositions[0].label }}
                            </v-chip>
                        </v-col>

                        <v-divider v-if="index !== questions_read.length - 1" class="pb-2"/>
                    </v-row>
                </v-card-text>

                <v-card-actions class="mx-2 mb-2">
                    <v-row>
                        <v-col cols="12">
                            <v-btn @click="dialog_questions_with_answer = false" class="mr-2" color="primary" block
                                   variant="outlined"
                            >
                                {{ $t('play_game_death_minute_view.close') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import QuestionRepository from "../../../services/QuestionRepository";

export default {
    name: "PlayGameMinuteOfDeathView",
    data() {
        return {
            game_mode: this.$store.getters['game_mode/game_mode'],
            dialog_which_player_begin: true,
            player_one: this.$store.getters['player/players'][0].pseudo,
            player_two: this.$store.getters['player/players'][1].pseudo,
            player_which_play: '',

            text_who_is_next: '',

            loading_questions: false,
            questions: [],
            questions_read: [],
            index_question_display: 0,
            current_question: {},
            is_game_stop: false,

            is_ready: false,
            is_end: false,
            time_out_player_one: null,
            time_player_one: 60,
            time_out_player_two: null,
            time_player_two: 60,

            dialog_edit_time_player: false,
            player_time_to_edit: '',
            player_actual_time_to_edit: null,

            player_winner: '',
            dialog_questions_with_answer: false
        }
    },
    methods: {
        getGameDeathMinuteQuestions() {
            this.loading_questions = true;

            QuestionRepository.getQuestionsWithAnswerByGameModeId(this.game_mode.id, 30).then(response => {
                this.text_who_is_next = this.$t('play_game_death_minute_view.its_turn_to_play', [this.player_which_play]);
                this.questions = response.data;
                this.dialog_which_player_begin = false;
            }).catch(error => {
                this.manageError(error, this.$t('play_game_death_minute_view.error_encountered_get_questions'));
            }).finally(() => {
                this.loading_questions = false;
            });
        },

        async getOtherQuestions() {
            QuestionRepository.getQuestionsWithAnswerByGameModeId(this.game_mode.id, 15).then(response => {
                response.data.forEach((question) => {
                    this.questions.push(question);
                });
            }).catch(error => {
                this.manageError(error, this.$t('play_game_death_minute_view.error_encountered_get_next_questions'));
            });
        },

        launchGame() {
            this.current_question = this.questions[this.index_question_display];

            if (this.player_which_play === this.player_one) {
                this.countDownTimerOne();
            } else {
                this.countDownTimerTwo();
            }

            this.is_ready = true;
        },

        countDownTimerOne() {
            if (this.time_player_one > 0) {
                this.time_out_player_one = setTimeout(() => {
                    this.time_player_one -= 1;
                    this.countDownTimerOne()
                }, 1000);
            } else {
                this.finishGame(this.player_one);
            }
        },

        countDownTimerTwo() {
            if (this.time_player_two > 0) {
                this.time_out_player_two = setTimeout(() => {
                    this.time_player_two -= 1;
                    this.countDownTimerTwo()
                }, 1000);
            } else {
                this.finishGame(this.player_two);
            }
        },

        goodAnswerOfPlayer() {
            if (this.player_which_play === this.player_one) {
                clearTimeout(this.time_out_player_one);
                this.player_which_play = this.player_two;
            } else {
                clearTimeout(this.time_out_player_two);
                this.player_which_play = this.player_one;
            }

            this.text_who_is_next = this.$t('play_game_death_minute_view.its_turn_to_play', [this.player_which_play]);
            this.is_ready = false;
            this.questions[this.index_question_display].user_has_good_answer = true;
            this.questions_read.push(this.questions[this.index_question_display]);

            this.index_question_display++;
            this.current_question = this.questions[this.index_question_display];

            if (this.index_question_display + 3 === this.questions.length) {
                this.getOtherQuestions();
            }
        },

        badAnswerOfPlayer() {
            this.questions[this.index_question_display].user_has_good_answer = false;
            this.questions_read.push(this.questions[this.index_question_display]);
            this.index_question_display++;
            this.current_question = this.questions[this.index_question_display];

            if (this.index_question_display + 3 === this.questions.length) {
                this.getOtherQuestions();
            }
        },

        stopGame() {
            if (this.player_which_play === this.player_one) {
                clearTimeout(this.time_out_player_one);
            } else {
                clearTimeout(this.time_out_player_two);
            }
            this.is_game_stop = true;
        },

        relaunchGame() {
            if (this.player_which_play === this.player_one) {
                this.countDownTimerOne();
            } else {
                this.countDownTimerTwo();
            }
            this.is_game_stop = false;
        },

        openDialogEditPlayerTime(player) {
            this.player_actual_time_to_edit = (this.player_one === player ? this.time_player_one : this.time_player_two);
            this.player_time_to_edit = player;
            this.dialog_edit_time_player = true;
        },

        editTimePlayer() {
            if (this.player_time_to_edit === this.player_one) {
                this.time_player_one = this.player_actual_time_to_edit;
            } else {
                this.time_player_two = this.player_actual_time_to_edit;
            }

            this.dialog_edit_time_player = false;
        },

        finishGame(player) {
            this.is_end = true;
            this.is_ready = false;
            this.player_winner = (this.player_one === player ? this.player_two : this.player_one);

            clearTimeout(this.time_out_player_one);
            clearTimeout(this.time_out_player_two);

            this.increaseTotalSeenOfQuestionRead();
        },

        async increaseTotalSeenOfQuestionRead() {
            let idQuestions = this.questions_read.map(question => question.id);
            if(idQuestions.length > 0) {
                await QuestionRepository.patchIncreaseTotalSeen(idQuestions);
            }
        }
    },
}
</script>
