import {IPlayer} from "@/models/IPlayer";

const state = () => ({
    players: [],
});

const mutations = {
    SET_PLAYER(state, player: IPlayer): void {
        state.players.push(player);
    },

    UNSET_PLAYER(state, player: IPlayer): void {
        state.players.splice(state.players.indexOf(player), 1);
    },

    UNSET_ALL_PLAYERS(state): void {
        state.players = [];
    }
};

const actions = {
    async setPlayer({commit}, player: IPlayer): Promise<void> {
        await commit('SET_PLAYER', player);
    },

    async unsetPlayer({commit}, player: IPlayer): Promise<void> {
        await commit('UNSET_PLAYER', player);
    },

    async unsetAllPlayers({commit}): Promise<void> {
        await commit('UNSET_ALL_PLAYERS');
    }
};

const getters = {
    players: (state): void => state.players
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
