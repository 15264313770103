<template>
    <div id="dialog-report-component">
        <span @click="dialog_report = true;" class="text-decoration-underline pointer text-primary">
            {{ $t('dialog_report_component.signal_problem') }}
        </span>

        <v-dialog v-model="dialog_report" max-width="500px">
            <v-form ref="form_report">
                <v-card>
                    <v-card-title>{{ $t('dialog_report_component.signal_problem') }}</v-card-title>

                    <v-card-text class="py-2 px-4">
                        <v-row class="pb-0">
                            <v-col cols="12">
                                <v-textarea v-model="description" :rules="description_rules"
                                            :label="$t('dialog_report_component.description_problem')" rows="4"
                                            variant="outlined" color="primary"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end pb-4 pt-0">
                        <v-btn :loading="loading_report" @click="postMessage()"
                               class="font-weight-bold mr-2" color="primary" variant="flat"
                        >
                            {{ $t('dialog_report_component.signal') }}
                        </v-btn>

                        <v-btn @click="dialog_report = false"
                               class="mr-2" color="primary" variant="outlined"
                        >
                            {{ $t('dialog_report_component.close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import MessageRepository from "../../services/MessageRepository";

export default {
    name: "DialogReportComponent",
    props: {
        game_mode_id: {
            type: Number,
            require: true
        },
        question_id: {
            type: Number,
            require: true
        },
        type: {
            type: String,
            require: true
        }
    },
    data() {
        return {
            dialog_report: false,
            loading_report: false,
            description: '',
            description_rules: [
                v => !!v || this.$t('dialog_report_component.description_required')
            ],
        }
    },
    methods: {
        async postMessage() {
            const {valid} = await this.$refs.form_report.validate();

            if (valid) {
                this.loading_report = true;

                MessageRepository.postMessage({
                    game_mode_id: this.game_mode_id,
                    question_id: this.question_id,
                    type: this.type,
                    description: this.description
                }).then(() => {
                    this.showSnackbar('success', this.$t('dialog_report_component.message_send'));
                }).catch(error => {
                    this.manageError(error, this.$t('dialog_report_component.error_encountered_send_message'));
                }).finally(() => {
                    this.dialog_report = false;
                    this.loading_report = false;
                    this.description = '';
                });
            }
        }
    }
}
</script>