import axios from '@/plugins/axios';
import type {AxiosResponse} from 'axios';

const resource = 'api/message'

export default {
    postMessage(message: any): Promise<AxiosResponse<null>> {
        return axios.post(`${resource}/authenticate`, message);
    }
}
