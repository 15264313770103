<template>
    <div id="send-remark-component" class="w-100">
        <v-card elevation="4" class="d-flex flex-column justify-center py-2">
            <v-card-title>{{ $t('send_remark_component.what_think_project') }}</v-card-title>

            <v-card-actions class="px-4">
                <v-btn @click="dialog_send_remark = true" class="font-weight-bold" size="large"
                       block color="primary" variant="flat"
                >
                    {{  $t('send_remark_component.send_remark') }}
                </v-btn>
            </v-card-actions>
        </v-card>

        <v-dialog v-model="dialog_send_remark" max-width="500px">
            <v-form ref="form_send_remark">
                <v-card>
                    <v-card-title>{{ $t('send_remark_component.your_remark') }}</v-card-title>

                    <v-card-text class="py-2 px-4">
                        <v-row class="pb-0">

                            <v-col class="pb-0" cols="12">
                                <v-select :items="remark_types" :rules="remark_type_rules"
                                          :label=" $t('send_remark_component.remark_type')"
                                          v-model="remark_type_selected" variant="outlined" color="primary"
                                          item-title="text" item-value="type"
                                />
                            </v-col>

                            <v-col cols="12">
                                <v-textarea v-model="description" :rules="description_rules"
                                            :label="$t('send_remark_component.description')" rows="4"
                                            variant="outlined" color="primary"
                                />
                            </v-col>
                        </v-row>
                    </v-card-text>

                    <v-card-actions class="justify-end pb-4 pt-0">
                        <v-btn :loading="loading_send_remark" @click="postMessage()"
                               class="font-weight-bold mr-2" color="primary" variant="flat"
                        >
                            {{  $t('send_remark_component.send') }}
                        </v-btn>

                        <v-btn @click="closeDialogSendRemark()" class="mr-2" color="primary" variant="outlined">
                            {{ $t('send_remark_component.close') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import MessageRepository from "../../services/MessageRepository";

export default {
    name: "SendRemarkComponent",
    data() {
        return {
            dialog_send_remark: false,
            loading_send_remark: false,
            remark_types: [{
                type: 'propose_question',
                text: this.$t('send_remark_component.propose_question')
            }, {
                type: 'send_bug',
                text: this.$t('send_remark_component.send_bug')
            }, {
                type: 'contact_us',
                text: this.$t('send_remark_component.contact_us')
            }, {
                type: 'signal_question',
                text: this.$t('send_remark_component.signal_question')
            }],
            description: '',
            remark_type_selected: '',

            remark_type_rules: [
                v => !!v || this.$t('send_remark_component.remark_type')
            ],
            description_rules: [
                v => !!v || this.$t('send_remark_component.description_required')
            ],
        }
    },
    methods: {
        closeDialogSendRemark() {
            this.dialog_send_remark = false;
            this.description = '';
            this.remark_type_selected = null;
        },

        async postMessage() {
            const {valid} = await this.$refs.form_send_remark.validate();

            if (valid) {
                this.loading_send_remark = true;

                MessageRepository.postMessage({
                    type: this.remark_type_selected,
                    description: this.description
                }).then(() => {
                    this.showSnackbar('success', this.$t('send_remark_component.thanks_for_return'));
                }).catch(error => {
                    this.manageError(error, this.$t('send_remark_component.error_encountered_send_remark'));
                }).finally(() => {
                    this.closeDialogSendRemark();
                    this.loading_send_remark = false;
                });
            }
        }
    }
}
</script>