import {createApp} from 'vue';
import App from "./App.vue";

import auth from './plugins/auth';
import global from "./plugins/global";
import i18n from "./plugins/i18n";
import router from "./plugins/router";
import store from "./plugins/store";
import vuetify from "./plugins/vuetify";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js'

ChartJS.register(ArcElement, Tooltip, Legend)
const app = createApp(App);

app.use(auth);
app.mixin(global);
app.use(i18n);
app.use(router);
app.use(store);
app.use(vuetify);

app.mount('#app');
