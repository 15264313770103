import {IGameMode} from "@/models/IGameMode";

const state = () => ({
    game_modes: [],
    game_mode: null,
});

const mutations = {
    SET_CURRENT_GAME_MODE(state, gameMode: IGameMode): void {
        state.game_mode = gameMode;
    },

    SET_GAME_MODES(state, gameModes: IGameMode[]): void {
        state.game_modes = gameModes;
    }
};

const actions = {
    async setCurrentGameMode({commit}, gameMode: IGameMode): Promise<void> {
        await commit('SET_CURRENT_GAME_MODE', gameMode);
    },

    async setGameModes({commit}, gameModes: IGameMode[]): Promise<void> {
        await commit('SET_GAME_MODES', gameModes);
    }
};

const getters = {
    game_modes: (state): void => state.game_modes,
    game_mode: (state): void => state.game_mode
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
